"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
class Document {
    constructor(header, sections) {
        this._header = header;
        this._sections = sections;
    }
    get header() {
        return this._header;
    }
    get sections() {
        return this._sections;
    }
    /**
     * Conerts the data sections into a JavaScript object.
     */
    toJSON() {
        const sectionsLen = this._sections?.length || 0;
        let data = null;
        // Only one section
        if (sectionsLen === 1) {
            const section = this._sections?.get(0);
            data = section.toJSON();
        }
        // More than one section
        else {
            data = {};
            for (let i = 0; i < sectionsLen; i++) {
                const section = this._sections?.get(i);
                data[section.name] = section.toJSON();
            }
        }
        if (this.header.definitions?.length) {
            const headerObject = this.header.toJSON();
            if (headerObject) {
                return {
                    header: headerObject,
                    data
                };
            }
        }
        return data;
    }
}
exports.default = Document;
