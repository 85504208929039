"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/**
* Enumeration representing types of tokens.
*/
var TokenType;
(function (TokenType) {
    TokenType["CURLY_OPEN"] = "CURLEY_OPEN";
    TokenType["CURLY_CLOSE"] = "CURLY_CLOSE";
    TokenType["BRACKET_OPEN"] = "BRACKET_OPEN";
    TokenType["BRACKET_CLOSE"] = "BRACKET_CLOSE";
    TokenType["COLON"] = "COLON";
    TokenType["COMMA"] = "COMMA";
    TokenType["STRING"] = "STRING";
    TokenType["BINARY"] = "BINARY";
    TokenType["NUMBER"] = "NUMBER";
    TokenType["BIGINT"] = "BIGINT";
    TokenType["DECIMAL"] = "DECIMAL";
    TokenType["BOOLEAN"] = "BOOLEAN";
    TokenType["NULL"] = "NULL";
    TokenType["UNDEFINED"] = "UNDEFINED";
    TokenType["DATETIME"] = "DATETIME";
    TokenType["DATE"] = "DATE";
    TokenType["TIME"] = "TIME";
    TokenType["WHITESPACE"] = "WHITESPACE";
    TokenType["SECTION_SEP"] = "SECTION_SEP";
    TokenType["SECTION_SCHEMA"] = "SECTION_SCHEMA";
    TokenType["SECTION_NAME"] = "SECTION_NAME";
    TokenType["COLLECTION_START"] = "COLLECTION_START";
    TokenType["UNKNOWN"] = "UNKNOWN";
})(TokenType || (TokenType = {}));
exports.default = TokenType;
